import {ViewChild, ElementRef, Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators, FormArray   } from '@angular/forms';
import {IssueService} from '../services/issue.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setqr-code',
  templateUrl: './setqr-code.component.html',
  styleUrls: ['./setqr-code.component.scss']
})
export class SetqrCodeComponent implements OnInit {

	issue:any;
	errors=[];
	id: number;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private issueService: IssueService) { }

  ngOnInit() {
  	this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number
		console.log(this.id);
  	this.getQrIssues(this.id);
     });
  }

  getQrIssues(id):void {
	  this.issueService.getQrIssues(id) .then(result => {
	  this.issue=result;
	 	})
	          .catch(error => {
	           console.log(error);
	          });
	}

}
