import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-subgroup",
  templateUrl: "./subgroup.component.html",
  styleUrls: ["./subgroup.component.css"]
})
export class SubgroupComponent implements OnInit {
  subgroupForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    name: ["", Validators.required],
    group_id: ["", Validators.required]
  });

  subgroups: any[];
  errors = [];
  groups: any[];
  editIndex: any;
  p: any;
  user: any;

  constructor(private fb: FormBuilder, private masterService: MasterService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.getSubgroups();
    this.getGroups();
  }

  getGroups(): void {
    this.masterService.getGroups().then(result => {
      this.groups = result;
    });
  }

  getSubgroups(): void {
    this.masterService
      .getSubgroups()
      .then(result => {
        this.subgroups = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.subgroupForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    // TODO: Use EventEmitter with form value
    let data = this.subgroupForm.value;
    this.masterService
      .saveSubgroup(data)
      .then(result => {
        swal("Data Successfully Saved", " ", "success");
        this.subgroups.push(result);
        this.subgroupForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  setEditModal(subgroup, index) {
    this.editIndex = index;
    this.subgroupForm.setValue({
      id: subgroup.id,
      name: subgroup.name,
      group_id: subgroup.group_id
    });
  }
  onUpdate(id): void {
    let data = this.subgroupForm.value;

    console.log(data);
    this.masterService
      .updateSubgroup(data, id)
      .then(result => {
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.subgroups[this.editIndex] = result;
        this.subgroupForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteSubgroup(subgroup) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.masterService
      .deleteSubgroup(subgroup.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.subgroups.splice(this.subgroups.indexOf(subgroup), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
