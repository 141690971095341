import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router'; 
import {TokenService} from '../services/token.service';

import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate {

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean |
	Observable<boolean> | Promise<boolean> {
    return this.tokenService.loggedIn();
	}
 
  constructor(private tokenService: TokenService) { }
}
