import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormArray
} from "@angular/forms";
import { TransactionServiceService } from "../services/transaction-service.service";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";
import { Globals } from "../globals";

@Component({
  selector: "app-fund-transfer",
  templateUrl: "./fund-transfer.component.html",
  styleUrls: ["./fund-transfer.component.scss"]
})
export class FundTransferComponent implements OnInit {
  sessions: any[] = [];

  reportTwoForm = this.fb.group({
    group_id: ["", Validators.required],
    subgroup_id: ["", Validators.required],
    from_date: ["", Validators.required],
    to_date: ["", Validators.required],
    report_type: ["ReceiptAndPayment", Validators.required]
  });

  errors = [];
  groups: any[];
  selectedGroup: any;
  subgroups: any[];
  selectedSubgroup: any;
  searchParams: any;
  transactionTypes: any[];
  cr_transactions: any[];
  dr_transactions: any[];
  openingBalance: any;
  closingBalance: any;
  currentDate: any;
  p: any;
  constructor(
    private globals: Globals,
    private fb: FormBuilder,
    private transactionServiceService: TransactionServiceService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.getGroups();
    this.gettransactionTypes();
    this.sessions = this.globals.sessions;
  }

  getGroups(): void {
    this.masterService.getGroups().then(result => {
      this.groups = result;
    });
  }

  getSubgroups(): void {
    this.masterService
      .getSubgroups(this.reportTwoForm.getRawValue().group_id)
      .then(result => {
        this.subgroups = result;
      });
  }

  gettransactionTypes(): void {
    this.masterService.gettransactionType().then(result => {
      this.transactionTypes = result;
    });
  }

  onSubmit(): void {
    this.selectedGroup = this.groups.find(
      x => x.id == this.reportTwoForm.value.group_id
    );
    this.selectedSubgroup = this.subgroups.find(
      x => x.id == this.reportTwoForm.value.subgroup_id
    );
    this.currentDate = Date.now();
    console.log(this.currentDate);

    this.searchParams = this.reportTwoForm.value;
    console.log(this.searchParams);
    this.transactionServiceService
      .SearchCashBook(this.searchParams)
      .then(result => {
        console.log(result);
        this.cr_transactions = result.cr_transactions;

        this.cr_transactions.forEach(function(obj1, key1) {
          obj1.transaction_type_id = obj1["transaction_types"][0].id;
        });

        var newArr = [];
        this.cr_transactions.forEach((item, index) => {
          if (
            newArr.findIndex(
              i => i.transaction_type_id == item.transaction_type_id
            ) === -1
          ) {
            newArr.push(item);
          } else {
            newArr[
              newArr.findIndex(
                i => i.transaction_type_id == item.transaction_type_id
              )
            ].cash =
              parseInt(
                newArr[
                  newArr.findIndex(
                    i => i.transaction_type_id == item.transaction_type_id
                  )
                ].cash
              ) + parseInt(item.cash);

            newArr[
              newArr.findIndex(
                i => i.transaction_type_id == item.transaction_type_id
              )
            ].bank =
              parseInt(
                newArr[
                  newArr.findIndex(
                    i => i.transaction_type_id == item.transaction_type_id
                  )
                ].bank
              ) + parseInt(item.bank);
          }
        });
        this.cr_transactions = newArr;
        console.log(this.cr_transactions); // Result

        this.dr_transactions = result.dr_transactions;

        this.dr_transactions.forEach(function(obj1, key1) {
          obj1.transaction_type_id = obj1["transaction_types"][0].id;
        });

        var newArr = [];
        this.dr_transactions.forEach((item, index) => {
          if (
            newArr.findIndex(
              i => i.transaction_type_id == item.transaction_type_id
            ) === -1
          ) {
            newArr.push(item);
          } else {
            newArr[
              newArr.findIndex(
                i => i.transaction_type_id == item.transaction_type_id
              )
            ].cash =
              parseInt(
                newArr[
                  newArr.findIndex(
                    i => i.transaction_type_id == item.transaction_type_id
                  )
                ].cash
              ) + parseInt(item.cash);

            newArr[
              newArr.findIndex(
                i => i.transaction_type_id == item.transaction_type_id
              )
            ].bank =
              parseInt(
                newArr[
                  newArr.findIndex(
                    i => i.transaction_type_id == item.transaction_type_id
                  )
                ].bank
              ) + parseInt(item.bank);
          }
        });
        this.dr_transactions = newArr;

        this.openingBalance = result.opening_balance;
        // this.closingBalance=result.closing_balance;
      });
  }

  getCreditTotalCash() {
    const total = this.cr_transactions.reduce(
      (sum, item) => +sum + +item.cash,
      0
    );
    return total;
  }
  getCreditLastBalance() {
    let last: any = this.cr_transactions[this.cr_transactions.length - 1];
    if (last) return parseInt(last.cash_balance) + parseInt(last.bank_balance);
  }

  getCreditTotalBank() {
    const total = this.cr_transactions.reduce(
      (sum, item) => +sum + +item.bank,
      0
    );
    return total;
  }
  getDebitTotalCash() {
    const total = this.dr_transactions.reduce(
      (sum, item) => +sum + +item.cash,
      0
    );
    return total;
  }
  getDebitTotalBank() {
    const total = this.dr_transactions.reduce(
      (sum, item) => +sum + +item.bank,
      0
    );
    return total;
  }
  getDebitLastCashBalance() {
    let last: any = this.dr_transactions[this.dr_transactions.length - 1];
    if (last) return last.cash_balance;
  }
  getDebitLastBankBalance() {
    let last: any = this.dr_transactions[this.dr_transactions.length - 1];
    if (last) return last.bank_balance;
  }
  getDebitLastBalance() {
    let last: any = this.dr_transactions[this.dr_transactions.length - 1];
    if (last) return parseInt(last.cash_balance) + parseInt(last.bank_balance);
  }

  setDates(event: Event) {
    let selectedOptions = event.target["options"];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementValue = selectedOptions[selectedIndex].value;
    let session = this.sessions.find(x => x.id == selectElementValue);
    this.reportTwoForm.patchValue({
      from_date: session.from,
      to_date: session.to
    });
    console.log(this.reportTwoForm.value);
  }
}
