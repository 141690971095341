import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = "http://api.chilaraicollegeaccounts.in/api";
  // apiUrl: string = "http://localhost:8000/api";
  constructor() {
    for (this.i = 2016; this.i < 2025; this.i++) {
      this.sessions.push({
        id: this.i,
        from: new Date(this.i + "-04-01").toISOString().slice(0, 10),
        to: new Date(this.i + +1 + "-03-31").toISOString().slice(0, 10),
        name: this.i + " - " + (this.i + +1)
      });
      console.log(this.sessions);
    }
  }

  sessions: any[] = [];
  i: number = 2016;
}
