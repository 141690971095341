import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators, FormArray   } from '@angular/forms';
import {PurchaseService} from '../services/purchase.service';
import {MasterService} from '../services/master.service';

import swal from 'sweetalert';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

	purchaseForm =this.fb.group({
   id: [{value:null, disabled:true}],
   date:['', Validators.required],
   invoice_no:['', Validators.required],
   party:['', Validators.required],
   project_id:['', Validators.required],
   purchase_detail: this.fb.array([this.initItemRow()]),
   
 });
  

purchases:any[];
 errors=[];
 editIndex:any;
projects:any[];
items:any[];
parties:any[];
p:any;


  constructor(private fb: FormBuilder, private purchaseService: PurchaseService, private masterService: MasterService) { }

  ngOnInit() {
  	// this.getPurchases();
    this.getProjects();
    this.getItems();
  	this.getParties();
  }

   initItemRow(){
  return  this.fb.group({
   item:['', Validators.required],
   rate:['', Validators.required],
   quantity:['', Validators.required],
   discount:[''],
   tax:[''],
   cgst:[''],
   igst:[''],
   sgst:[''],
   amount:['', Validators.required],
    })
 }
 addNewRow(){
      const control = <FormArray>this.purchaseForm.controls['purchase_detail'];
      control.push(this.initItemRow());  
  }

   deleteRow(index: number){
      const control = <FormArray>this.purchaseForm.controls['purchase_detail'];
      if (control != null) {
        var TotalRow=control.value.length;
      }
      if(TotalRow >1)
      {
        control.removeAt(index);
      }
      else{
        alert('One record is mandatory.');
        return false;
      }
  }

	getProjects():void {
     this.masterService.getProjects() .then(result => {
    this.projects=result;
     });
  }

  getItems():void {
     this.masterService.getItems() .then(result => {
    this.items=result;
     });
  } 

   getParties():void {
	   this.masterService.getParties() .then(result => {
	  this.parties=result;
	   });
	}

 //  getPurchases():void {
	//  	this.purchaseService.getPurchases() .then(result => {
	//   this.purchases=result;
	//  	})
	//           .catch(error => {
	//            console.log(error);
	//           });
	// }
	onSubmit():void {
		  // TODO: Use EventEmitter with form value
		 let data=this.purchaseForm.getRawValue();
		    this.onSave();
		 
		}

	onSave(){
		  let data=this.purchaseForm.value;
		 
		   this.purchaseService.savePurchase(data) .then(result => {
		   // alert("Success...!")
		   swal("Data Successfully Saved"," ", "success");

		// this.purchases.push(result);
		this.purchaseForm.reset()
		   })
		          .catch(error => {
		             if (error.status==422) {
		              this.errors.push(error.error.name[0]);

		              console.log(this.errors); 
		            }

		          });
		}

}
