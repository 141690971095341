import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators   } from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {TokenService} from '../services/token.service';
import {Router}  from '@angular/router';

import swal from 'sweetalert';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


 loginForm =this.fb.group({
   email:['', Validators.required],
   password:['', Validators.required],
 });
 error=[];
  
  constructor(private fb: FormBuilder, private authService: AuthService, private tokenService: TokenService, private router: Router) { }

  ngOnInit() {
  }


onSubmit(){
    
  let data=this.loginForm.value;
 
   this.authService.login(data) .then(result => {
   
this.tokenService.handle(result.access_token);
this.authService.changeAuthStatus(true);
localStorage.setItem('user', JSON.stringify(result.user));

this.router.navigateByUrl('/dashboard');

   })
          .catch(error => {
    
          	this.error=error.error.error;
           console.log(error.error);

          });
}

}
