import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {TransactionServiceService} from '../services/transaction-service.service';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {


  transactionForm = this.fb.group({
    id: [{value:null, disabled:true}],
    date: ['', Validators.required],
     credit: this.fb.array([]),
     debit: this.fb.array([]),
     cd:this.fb.array([]),
     cw:this.fb.array([])
  });

  balances:any[];
searchText:any;
 transactions:any[];
 errors=[];
transactionTypes:any[];
groups:any[];
subgroups:any={'credit': [], 'debit': [], 'cd':[],'cw':[]};
editIndex:any;
p:any;
page:any;


  constructor(private route: ActivatedRoute, private fb: FormBuilder, private transactionServiceService: TransactionServiceService, private masterService: MasterService) { }
  resetFormGroup(){
    this.transactionForm = this.fb.group({
        id: [{value:null, disabled:true}],
        date: ['', Validators.required],
         credit: this.fb.array([]),
         debit: this.fb.array([]),
         cd:this.fb.array([]),
         cw:this.fb.array([])
      });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
       this.page = params['page']; // (+) converts string 'id' to a number
       console.log(this.page);
       if(this.page=='cr'){
         this.resetFormGroup();

         this.addNewCreditRow();

       }
       else if(this.page=='dr'){
         this.resetFormGroup();

         this.addNewDebitRow();

       }else if(this.page=='cd'){
         this.resetFormGroup();

         this.addNewCdRow();


       }else if(this.page=='cw'){
         this.resetFormGroup();

         this.addNewCwRow();


       }
       else if(this.page=='ft'){
         this.resetFormGroup();

         this.addNewFtRow();

       }

 this.gettransactionTypes();
this.getGroups();
// this.getBalances();

     });

    // this.gettransactions();


  }

 initItemRow(){
  return  this.fb.group({
   particulars:['', Validators.required],
   group_id:['', Validators.required],
   subgroup_id:['', Validators.required],
   transaction_type_id:['', Validators.required],
   cash:['', Validators.required],
   bank:['', Validators.required],
    payment_mode:[''],
   ref_no:[''],
    remarks:[''],
    })
 }

 initItemRowCd(){
  return  this.fb.group({
   particulars:['', Validators.required],
   group_id:['', Validators.required],
   subgroup_id:['', Validators.required],
   transaction_type_id:['', Validators.required],
   cash:['', Validators.required],
     payment_mode:[''],
   ref_no:[''],
    remarks:[''],
    })
 }
 initItemFt(){
  return  this.fb.group({
    group_id:['', Validators.required],
   subgroup_id:['', Validators.required],
   transaction_type_id:['', Validators.required],
    bank:['', Validators.required],
    payment_mode:[''],
   ref_no:[''],
    remarks:[''],
    particulars:['', Validators.required],

    })
 }
  addNewCreditRow(){
      const control = <FormArray>this.transactionForm.controls['credit'];
      control.push(this.initItemRow());
  }

  addNewDebitRow(){
      const control = <FormArray>this.transactionForm.controls['debit'];
      control.push(this.initItemRow());
  }


    addNewCdRow(){
        const control = <FormArray>this.transactionForm.controls['cd'];
        control.push(this.initItemRowCd());
    }
    addNewCwRow(){
        const control = <FormArray>this.transactionForm.controls['cw'];
        control.push(this.initItemRowCd());
    }

    addNewFtRow(){
        const control = <FormArray>this.transactionForm.controls['credit'];
        control.push(this.initItemFt());
        const control1 = <FormArray>this.transactionForm.controls['debit'];
        control1.push(this.initItemFt());
    }


  deleteDebitRow(index: number){
      const control = <FormArray>this.transactionForm.controls['debit'];
      if (control != null) {
        var TotalRow=control.value.length;
      }
      if(TotalRow >0)
      {
        control.removeAt(index);
      }
      else{
        alert('One record is mandatory.');
        return false;
      }
  }

   deleteCreditRow(index: number){
      const control = <FormArray>this.transactionForm.controls['credit'];
      if (control != null) {
        var TotalRow=control.value.length;
      }
      if(TotalRow >0)
      {
        control.removeAt(index);
      }
      else{
        alert('One record is mandatory.');
        return false;
      }
  }
  // getBalances():void {
  //    this.masterService.getBalances() .then(result => {
  //   this.balances=result;
  //    })
  //           .catch(error => {
  //            console.log(error);
  //           });
  // }
  getGroups():void {
   this.masterService.getGroups() .then(result => {
  this.groups=result;
   });
}

getSubgroups(type, index):void {
  console.log(type+index);
  if (type=='credit') {
 var group_id=this.transactionForm.getRawValue().credit[index].group_id;
   }
 if (type=='debit') {
 var group_id=this.transactionForm.getRawValue().debit[index].group_id;
   }
 if (type=='cd') {
 var group_id=this.transactionForm.getRawValue().cd[index].group_id;
   }

   this.masterService.getSubgroups(group_id) .then(result => {
console.log(this.subgroups);
  this.subgroups[type][index]=result;
  console.log(this.subgroups);
   });
}

gettransactionTypes():void {
   this.masterService.gettransactionType() .then(result => {
  this.transactionTypes=result;
   });
}

gettransactions():void {
   this.transactionServiceService.getTransaction() .then(result => {
  this.transactions=result;
   })
          .catch(error => {
           console.log(error);
          });
}


onSubmit():void {
  // TODO: Use EventEmitter with form value
 let data=this.transactionForm.getRawValue();

 // if (data.id) {
 //   this.onUpdate(data.id);
 //  }else{
    this.onSave()
  // }

}

 onSave():void {
  		// TODO: Use EventEmitter with form value
       let data=this.transactionForm.value;
        this.transactionServiceService.saveTransaction(data) .then(result => {
    // alert('Success');
    swal(result.message,"", "success");
		// this.transactions.push(result);

    this.transactionForm.reset();
       })
          .catch(error => {
             if (error.status==422) {
              this.errors.push(error.error.name[0]);
              console.log(this.errors);
            }
          });
   }


   viewBalance(subgroup_id){

       this.transactionServiceService.viewBalance(subgroup_id) .then(result => {
         this.balances=result;
         console.log(this.balances);
    // alert('Success');
     // this.transactions.push(result);

    // this.transactionForm.reset();
       })
          .catch(error => {
             if (error.status==422) {
              this.errors.push(error.error.name[0]);
              console.log(this.errors);
            }
          });

   }

//    setEditModal(transactions, index){
//       this.editIndex=index;
//        this.transactionForm.setValue({
//           id: transactions.id,
//           particulars:transactions.particulars,
//           group_id:transactions.group_id,
//           subgroup_id:transactions.subgroup_id,
//           transaction_type_id:transactions.transaction_type_id,
//           credit:transactions.credit,
//           debit:transactions.debit,
//           payment_mode:transactions.payment_mode,
//           ref_no:transactions.ref_no,
//           date:transactions.date,
//           remarks:transactions.remarks,
//       })
//     }

// onUpdate(id):void{
//   let data=this.transactionForm.value;

// console.log(data)
//    this.transactionServiceService.updateTransaction(data, id) .then(result => {
//     // alert("Success...!")
//     swal("Data Successfully Updated"," ", "success");
// console.log(result);
// this.transactions[this.editIndex]=result;
// this.transactionForm.reset()

//     })
//           .catch(error => {
//              if (error.status==422) {
//               this.errors.push(error.error.name[0]);

//               console.log(this.errors);
//             }

//           });
// }

// deletetransaction(transaction){
//           this.transactionServiceService.deletetransaction(transaction.id) .then(result => {
//     // alert(result.message)
//     swal(result.message,"", "success");
//               this.transactions.splice(this.transactions.indexOf(transaction), 1);
//             }) .catch(error => {
//     // alert(error.error.message)
//     swal(error.error.message,"","error");
//           });
//        }

}
