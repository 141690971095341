import { BrowserModule } from "@angular/platform-browser";
import { NgxPaginationModule } from "ngx-pagination";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { Globals } from "./globals";
import { AppComponent } from "./app.component";
import { GroupComponent } from "./group/group.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule
} from "@angular/material";
import { SubgroupComponent } from "./subgroup/subgroup.component";
import { TransactionTypeComponent } from "./transaction-type/transaction-type.component";
import { TransactionComponent } from "./transaction/transaction.component";
import { AddmoreComponent } from "./addmore/addmore.component";
import { BalanceComponent } from "./balance/balance.component";
import { ProjectComponent } from "./project/project.component";
import { PurchaseComponent } from "./purchase/purchase.component";
import { PurchaseViewComponent } from "./purchase-view/purchase-view.component";
import { PurchaseDetailComponent } from "./purchase-detail/purchase-detail.component";
import { TransactionViewComponent } from "./transaction-view/transaction-view.component";
import { ReportOneComponent } from "./report-one/report-one.component";
import { ReportTwoComponent } from "./report-two/report-two.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { NgHttpLoaderModule } from "ng-http-loader";
import { PurchaseReportComponent } from "./purchase-report/purchase-report.component";
import { ReportThreeComponent } from "./report-three/report-three.component";
import { FilterPipe } from "./filter.pipe";
import { StockComponent, UniquePipe } from "./stock/stock.component";
import { DepartmentComponent } from "./department/department.component";
import { IssueComponent } from "./issue/issue.component";
import { PurchaseReturnComponent } from "./purchase-return/purchase-return.component";
import { QRCodeModule } from "angularx-qrcode";
import { IssueReturnComponent } from "./issue-return/issue-return.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SetqrCodeComponent } from "./setqr-code/setqr-code.component";
import { IssueStockComponent } from "./issue-stock/issue-stock.component";
import { CashBookComponent } from "./cash-book/cash-book.component";
import { UniversalReportComponent } from "./universal-report/universal-report.component";
import { FundTransferComponent } from "./fund-transfer/fund-transfer.component";
import { GrantRecordComponent } from "./grant-record/grant-record.component";
import { CashAccountComponent } from "./cash-account/cash-account.component";
import { NgxPrintModule } from "ngx-print";

@NgModule({
  declarations: [
    AppComponent,
    GroupComponent,
    SubgroupComponent,
    TransactionTypeComponent,
    TransactionComponent,
    AddmoreComponent,
    BalanceComponent,
    ProjectComponent,
    PurchaseComponent,
    PurchaseViewComponent,
    PurchaseDetailComponent,
    TransactionViewComponent,
    ReportOneComponent,
    ReportTwoComponent,
    DashboardComponent,
    LoginComponent,
    PurchaseReportComponent,
    ReportThreeComponent,
    FilterPipe,
    StockComponent,
    UniquePipe,
    DepartmentComponent,
    IssueComponent,
    PurchaseReturnComponent,
    IssueReturnComponent,
    ResetPasswordComponent,
    SetqrCodeComponent,
    IssueStockComponent,
    CashBookComponent,
    UniversalReportComponent,
    FundTransferComponent,
    GrantRecordComponent,
    CashAccountComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgHttpLoaderModule.forRoot(),
    QRCodeModule,
    NgxPrintModule
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule {}
