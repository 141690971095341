import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';

@Component({
  selector: 'app-addmore',
  templateUrl: './addmore.component.html',
  styleUrls: ['./addmore.component.scss']
})
export class AddmoreComponent implements OnInit {

	FormGroup:FormGroup;
	TotalRow: number;
p:any;
  
  constructor(private _fb: FormBuilder) { }

  ngOnInit() {

  	this.FormGroup=this._fb.group({
  		itemRows:this._fb.array([this.initItemRow()]),
  	});
  }

  initItemRow(){
  	return this._fb.group({
  		name:[''],
  		address:[''],
  		contact_no:[''],
  	});
  }

  addNewRow(){
  		const control = <FormArray>this.FormGroup.controls['itemRows'];
  		control.push(this.initItemRow());	
  }

  deleteRow(index: number){
  		const control = <FormArray>this.FormGroup.controls['itemRows'];
  		if (control != null) {
  			this.TotalRow=control.value.length;
  		}
  		if(this.TotalRow >1)
  		{
  			control.removeAt(index);
  		}
  		else{
  			alert('One record is mandatory.');
  			return false;
  		}
	}
}

