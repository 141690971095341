import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators, FormArray   } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {PurchaseService} from '../services/purchase.service';
import {MasterService} from '../services/master.service';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss']
})
export class PurchaseReportComponent implements OnInit {

	 reportForm =this.fb.group({
        project_id:[null, Validators.required],
	   from_date:[null, Validators.required],
	   to_date:'',
	 });

	purchases:any[];
	errors=[];
	projects:any[]
	searchParams:any[];
p:any;

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private purchaseService: PurchaseService, private masterService: MasterService) { }

  ngOnInit() {
   	this.getProjects();

   	  	this.route.queryParams.subscribe(params => {
       this.reportForm.controls['project_id'].setValue(+params['project_id']);

  	 		 this.onSubmit();
     });
  }

  	getProjects():void {
	   this.masterService.getProjects() .then(result => {
	  this.projects=result;
	   });
	}
 
	onSubmit():void {

		this.searchParams=this.reportForm.value;
		 console.log(this.searchParams);
		   this.purchaseService.purchaseReport(this.searchParams) .then(result => {
		   	console.log(result);
		   	this.purchases=result;
 		   });
		 
		}

	getTotalpurchase(){
		if (this.purchases) {
			 const total = this.purchases.reduce((sum, item) => +sum + +item.total_amount, 0) ;
		return total;
		}
	
		}


}
