import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], keys: any[], searchText: string): any[] {
  	console.log(keys)
    if(!items) return [];
    if(!searchText) return items;
searchText = searchText.toLowerCase();
return items.filter( it => {
	console.log(it);
	var key = 'it.'+keys[0];
	var key1 = 'it.'+keys[1];

      return  eval(key).toLowerCase().includes(searchText) || eval(key1).toLowerCase().includes(searchText);

    });
   }

}
