import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators   } from '@angular/forms';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

	projectForm =this.fb.group({
   id: [{value:null, disabled:true}],
   name:['', Validators.required],
   status:[''],
 });
  
  public status={
  0: 'Running',
  1: 'On Hold',
  2 : 'Completed'
  };

projects:any[];
 errors=[];
 editIndex:any;
p:any;

  constructor(private fb: FormBuilder, private masterService: MasterService) { }

  ngOnInit() {
  	this.getProjects();
  }

	getProjects():void {
	 	this.masterService.getProjects() .then(result => {
	  this.projects=result;
	 	})
	          .catch(error => {
	           console.log(error);
	          });
	}

		onSubmit():void {
		  // TODO: Use EventEmitter with form value
		 let data=this.projectForm.getRawValue();

		 if (data.id) {
		   this.onUpdate(data.id);
		  }else{
		    this.onSave()
		  }
		 
		}

	onSave(){
		  let data=this.projectForm.value;
		 
		   this.masterService.saveProject(data) .then(result => {
		   // alert("Success...!")
		   swal("Data Successfully Saved"," ", "success");

		this.projects.push(result);
		this.projectForm.reset()
		   })
		          .catch(error => {
		             if (error.status==422) {
		              this.errors.push(error.error.name[0]);

		              console.log(this.errors); 
		            }

		          });
		}
	setEditModal(project, index){
			this.editIndex=index;
			 this.projectForm.setValue({
			    id: project.id,
			    name: project.name,
			    status: project.status,
			  })
			}
	onUpdate(id):void{
		  let data=this.projectForm.value;

		console.log(data)
		   this.masterService.updateProject(data, id) .then(result => {
		    // alert("Success...!")
		    swal("Data Successfully Updated"," ", "success");
		console.log(result);
		this.projects[this.editIndex]=result;
		this.projectForm.reset()

		    })
		          .catch(error => {
		             if (error.status==422) {
		              this.errors.push(error.error.name[0]);

		              console.log(this.errors); 
		            }

		          });
		}
	deleteProject(project){ 

				var r = confirm("Are You Sure!");
			if (r == false) {
			 return;
			}      
          this.masterService.deleteProject(project.id) .then(result => {
  			 // alert(result.message)
    		swal(result.message,"", "success");
              this.projects.splice(this.projects.indexOf(project), 1);
            }) .catch(error => {
    		// alert(error.error.message)
   			 swal(error.error.message,"","error");
            });
       }

}
