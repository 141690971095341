import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators, FormArray   } from '@angular/forms';
import {PurchaseService} from '../services/purchase.service';
import {MasterService} from '../services/master.service';

import swal from 'sweetalert';

@Component({
  selector: 'app-purchase-view',
  templateUrl: './purchase-view.component.html',
  styleUrls: ['./purchase-view.component.scss']
})
export class PurchaseViewComponent implements OnInit {

	purchasesForm =this.fb.group({
	   project_id:'',
	   party:'',
	   from_date:'',
	   to_date:'',
	 });

	purchases:any[];
	errors=[];
	projects:any[]
	searchParams:any[];
	p:any;

  constructor(private fb: FormBuilder, private purchaseService: PurchaseService, private masterService: MasterService) { }

  ngOnInit() {
  	// this.getPurchases();
  	this.getProjects();
  }

  	getProjects():void {
	   this.masterService.getProjects() .then(result => {
	  this.projects=result;
	   });
	}

	//  getPurchases():void {
	//   this.purchaseService.getPurchases() .then(result => {
	//   this.purchases=result;
	//  	})
	//           .catch(error => {
	//            console.log(error);
	//           });
	// }

	onSubmit():void {		 

		this.searchParams=this.purchasesForm.value;
		 console.log(this.searchParams);
		   this.purchaseService.getFilterPurchase(this.searchParams) .then(result => {
		   	console.log(result);
		   	this.purchases=result;
 		   });
		 
		}

	deletePurchase(purchase){   

				var r = confirm("Are You Sure!");
			if (r == false) {
			 return;
			}    
          this.purchaseService.deletePurchase(purchase.id) .then(result => {
   			 swal(result.message,"", "success");
              this.purchases.splice(this.purchases.indexOf(purchase), 1);
            }) .catch(error => {
    		swal(error.error.message,"","error");
            });
       }

       getTotalpurchase(){
	 const total = this.purchases.reduce((sum, item) => +sum + +item.total_amount, 0) ;
		return total;
		}
}
