import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {TransactionServiceService} from '../services/transaction-service.service';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-report-three',
  templateUrl: './report-three.component.html',
  styleUrls: ['./report-three.component.scss']
})
export class ReportThreeComponent implements OnInit {

	reportThreeForm =this.fb.group({
	  
	   from_date:[''],
	   to_date:[''],
	 });

	errors=[];
	groups:any[];
	selectedGroup:any;
	subgroups:any[];
	selectedSubgroup:any;
	searchParams:any;
	transactionTypes:any[];
	transactions:any[];
	openingBalance:any;
	closingBalance:any;
	currentDate:any;
p:any;

  constructor(private fb: FormBuilder, private transactionServiceService: TransactionServiceService, private masterService: MasterService) { }

  ngOnInit() {
  
  	this.getGroups();
   	this.gettransactionTypes();
  }

  getGroups():void {
	   this.masterService.getGroups() .then(result => {
	  this.groups=result;
	   });
	}

	getSubgroups():void {
	   this.masterService.getSubgroups(this.reportThreeForm.getRawValue().group_id) .then(result => {
	  this.subgroups=result;
	   });
	}

	gettransactionTypes():void {
	   this.masterService.gettransactionType() .then(result => {
	  this.transactionTypes=result;
	   });
	}

	onSubmit():void {


 		 
		   	this.currentDate=  Date.now();
		   	console.log(this.currentDate);


		this.searchParams=this.reportThreeForm.value;
		 console.log(this.searchParams);
		   this.transactionServiceService.SearchReportThree(this.searchParams) .then(result => {
		   	console.log(result);
		   	this.transactions=result.transactions;
 		   });
		 
		}

}
