import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {TransactionServiceService} from '../services/transaction-service.service';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-report-two',
  templateUrl: './report-two.component.html',
  styleUrls: ['./report-two.component.scss']
})
export class ReportTwoComponent implements OnInit {

	reportTwoForm =this.fb.group({
	   group_id:['', Validators.required],
	   subgroup_id:['', Validators.required],
	   from_date:['', Validators.required],
	   to_date:['', Validators.required],
	 });

	errors=[];
	groups:any[];
	selectedGroup:any;
	subgroups:any[];
	selectedSubgroup:any;
	searchParams:any;
	transactionTypes:any[];
	cr_transactions:any[];
	dr_transactions:any[];
	openingBalance:any;
	closingBalance:any;
	currentDate:any;
p:any;

  constructor(private fb: FormBuilder, private transactionServiceService: TransactionServiceService, private masterService: MasterService) { }

  ngOnInit() {

  	this.getGroups();
   	this.gettransactionTypes();
  }

  getGroups():void {
	   this.masterService.getGroups() .then(result => {
	  this.groups=result;
	   });
	}

	getSubgroups():void {
	   this.masterService.getSubgroups(this.reportTwoForm.getRawValue().group_id) .then(result => {
	  this.subgroups=result;
	   });
	}

	gettransactionTypes():void {
	   this.masterService.gettransactionType() .then(result => {
	  this.transactionTypes=result;
	   });
	}

	onSubmit():void {


 		 this.selectedGroup =  this.groups.find(x => x.id ==  this.reportTwoForm.value.group_id);
 		 this.selectedSubgroup =  this.subgroups.find(x => x.id ==  this.reportTwoForm.value.subgroup_id);
		   	this.currentDate=  Date.now();
		   	console.log(this.currentDate);


		this.searchParams=this.reportTwoForm.value;
		 console.log(this.searchParams);
		   this.transactionServiceService.SearchReportTwo(this.searchParams) .then(result => {
		   	console.log(result);
		   	this.cr_transactions=result.cr_transactions;
		   	this.dr_transactions=result.dr_transactions;
		   	this.openingBalance=result.opening_balance;
		   	// this.closingBalance=result.closing_balance;
		   });
		 
		}


getCreditTotal(){
	 const total = this.cr_transactions.reduce((sum, item) => +sum + +item.credit, 0);
return total;
}
getDebitTotal(){
	 const total = this.dr_transactions.reduce((sum, item) => +sum + +item.debit, 0) ;
return total;
}
}
