import { Component, OnInit } from '@angular/core';
import {TransactionServiceService} from '../services/transaction-service.service';
import {PurchaseService} from '../services/purchase.service';
import {  FormBuilder, Validators   } from '@angular/forms';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

   projects:any[];  
   balances:any[];  
	filterKeys=[
	'group.name',
	'subgroup.name'
	];
   p:any;
searchText:any;
	today: number = Date.now();

  constructor(private fb: FormBuilder, private masterService: MasterService) { }

  ngOnInit() {
  	this.getProjects();
  	this.getBalances();
  }

  getProjects():void {
	 	this.masterService.getProjects() .then(result => {
	  this.projects=result;
	 	})
	          .catch(error => {
	           console.log(error);
	          });
	}

	getBalances():void {
	   this.masterService.getBalances() .then(result => {
	  this.balances=result;
	   })
	          .catch(error => {       
	           console.log(error);
	          });
	}

	getTotalbalance(){
		if (this.balances) {
	 var total = this.balances.reduce((sum, item) => +sum + +item.amount, 0) ;
		return total;

 		}
 		else{
		return 0;

 		}
		}
}
