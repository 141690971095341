import { ViewChild, ElementRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import { IssueService } from "../services/issue.service";
import { PurchaseService } from "../services/purchase.service";
import swal from "sweetalert";

@Component({
  selector: "app-issue",
  templateUrl: "./issue.component.html",
  styleUrls: ["./issue.component.scss"]
})
export class IssueComponent implements OnInit {
  @ViewChild("closeAddExpenseModal", { static: true })
  closeAddExpenseModal: ElementRef;

  issueForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    department_id: ["", Validators.required],
    item: ["", Validators.required],
    quantity: ["", Validators.required],
    issue_date: ["", Validators.required],
    status: ["", Validators.required],
    remarks: [""]
  });
  issueReturnForm = this.fb.group({
    issue_id: ["", Validators.required],
    item: ["", Validators.required],
    quantity: ["", Validators.required],
    return_date: ["", Validators.required],
    remarks: [""]
  });

  issueFilterForm = this.fb.group({
    item: "",
    department_id: "",
    from_date: "",
    to_date: ""
  });

  departments: any[];
  stocks: any[];
  errors = [];
  issues: any[];
  editIndex: any;
  issue: any;
  issuereturns = [];
  searchParams: any;
  p: any;
  items: any[];

  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    private issueService: IssueService,
    private purchaseService: PurchaseService
  ) {}

  ngOnInit() {
    this.getDepartments();
    this.getIssue();
    this.getPurchase_stocks();
    this.getItems();
  }

  getDepartments(): void {
    this.masterService.getDepartments().then(result => {
      this.departments = result;
    });
  }

  getItems(): void {
    this.masterService.getItems().then(result => {
      this.items = result;
    });
  }

  getPurchase_stocks(): void {
    this.purchaseService.getPurchase_stocks().then(result => {
      this.stocks = result;
    });
  }

  getIssue(): void {
    //  this.issueService.getIssue() .then(result => {
    // this.issues=result;
    //  })
    //         .catch(error => {
    //          console.log(error);
    //         });
    this.searchParams = this.issueFilterForm.value;
    console.log(this.searchParams);
    this.issueService.getIssue(this.searchParams).then(result => {
      console.log(result);
      this.issues = result;
    });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.issueForm.getRawValue();
    let issuereturn = this.issueReturnForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    // TODO: Use EventEmitter with form value
    let data = this.issueForm.value;
    this.issueService
      .saveIssue(data)
      .then(result => {
        swal("Data Successfully Saved", " ", "success");
        this.issues.push(result);
        this.issueForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  setEditModal(issue, index) {
    this.editIndex = index;
    this.issueForm.setValue({
      id: issue.id,
      department_id: issue.department_id,
      item: issue.item,
      quantity: issue.quantity,
      issue_date: issue.issue_date,
      status: issue.status,
      remarks: issue.remarks
    });
  }
  onUpdate(id): void {
    let data = this.issueForm.value;

    console.log(data);
    this.issueService
      .updateIssue(data, id)
      .then(result => {
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.issues[this.editIndex] = result;
        this.issueForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteIssue(issue) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.issueService
      .deleteIssue(issue.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.issues.splice(this.issues.indexOf(issue), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }

  issue_returnModal(issue) {
    this.issueReturnForm.setValue({
      issue_id: issue.id,
      item: issue.item,
      quantity: issue.quantity,
      return_date: "",
      remarks: ""
    });
  }

  onReturnSubmit() {
    let issuereturn = this.issueReturnForm.value;

    this.issueService
      .saveIssueReturn(issuereturn)
      .then(result => {
        this.closeAddExpenseModal.nativeElement.click();

        swal("Data Successfully Saved", " ", "success");
        // this.issuereturns.push(result);
        this.issueReturnForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }
}
