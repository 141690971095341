import { Component, OnInit, Pipe, PipeTransform } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";
import * as _ from "lodash";

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.scss"]
})
export class StockComponent implements OnInit {
  p: any;
  stockForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    item: ["", Validators.required],
    item_description: [""],
    stock_code: [""],
    source_of_funding: [""],
    remarks: [""],
    quantity: [""],
    damaged_quantity: [""]
  });

  stocks: any[];
  errors = [];
  editIndex: any;
  user: any;

  constructor(private fb: FormBuilder, private masterService: MasterService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.getStocks();
  }

  getStocks(): void {
    this.masterService
      .getStocks()
      .then(result => {
        this.stocks = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.stockForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    let data = this.stockForm.value;

    this.masterService
      .saveStock(data)
      .then(result => {
        // alert("Success...!")
        swal("Data Successfully Saved", " ", "success");

        this.stocks.push(result);
        this.stockForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  setEditModal(stock, index) {
    this.editIndex = index;
    this.stockForm.setValue({
      id: stock.id,
      name: stock.name
    });
  }

  onUpdate(id): void {
    let data = this.stockForm.value;

    console.log(data);
    this.masterService
      .updateStock(data, id)
      .then(result => {
        // alert("Success...!")
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.stocks[this.editIndex] = result;
        this.stockForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteStock(stock) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.masterService
      .deleteStock(stock.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.stocks.splice(this.stocks.indexOf(stock), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
@Pipe({
  name: "filterUnique",
  pure: false
})
export class UniquePipe implements PipeTransform {
  transform(value: any): any {
    if (value !== undefined && value !== null) {
      return _.uniqBy(value, "item");
    }
    return value;
  }
}
