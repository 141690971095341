import { ViewChild, ElementRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PurchaseService } from "../services/purchase.service";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";
@Component({
  selector: "app-purchase-return",
  templateUrl: "./purchase-return.component.html",
  styleUrls: ["./purchase-return.component.scss"]
})
export class PurchaseReturnComponent implements OnInit {
  @ViewChild("closeAddExpenseModal", { static: true })
  closeAddExpenseModal: ElementRef;

  purchaseReturnEditForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    purchase_detail_id: ["", Validators.required],
    return_date: ["", Validators.required],
    item: ["", Validators.required],
    quantity: ["", Validators.required],
    rate: ["", Validators.required],
    amount: ["", Validators.required],
    remarks: [""]
  });

  purchaseReturnFilterForm = this.fb.group({
    item: "",
    from_date: "",
    to_date: ""
  });

  purchaseReturns: any[];
  errors = [];
  editIndex: any;
  searchParams: any;
  p: any;
  items: any[];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private purchaseService: PurchaseService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.getpurchaseReturns();
    this.getItems();
  }

  getItems(): void {
    this.masterService.getItems().then(result => {
      this.items = result;
    });
  }

  getpurchaseReturns(): void {
    this.searchParams = this.purchaseReturnFilterForm.value;
    console.log(this.searchParams);
    this.purchaseService.getpurchaseReturn(this.searchParams).then(result => {
      console.log(result);
      this.purchaseReturns = result;
    });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.purchaseReturnEditForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.getpurchaseReturns();
    }
  }

  setEditModal(purchaseReturn, index) {
    this.editIndex = index;
    this.purchaseReturnEditForm.setValue({
      id: purchaseReturn.id,
      purchase_detail_id: purchaseReturn.purchase_detail_id,
      return_date: purchaseReturn.return_date,
      item: purchaseReturn.item,
      quantity: purchaseReturn.quantity,
      rate: purchaseReturn.rate,
      amount: purchaseReturn.amount,
      remarks: purchaseReturn.remarks
    });
  }

  onUpdate(id): void {
    let data = this.purchaseReturnEditForm.value;
    console.log(data);
    this.purchaseService
      .updatePurchaseReturn(data, id)
      .then(result => {
        // alert("Success...!")
        this.closeAddExpenseModal.nativeElement.click();
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.purchaseReturns[this.editIndex] = result;
        this.purchaseReturnEditForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deletePurchaseReturn(purchaseReturn) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.purchaseService
      .deletePurchaseReturn(purchaseReturn.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.purchaseReturns.splice(
          this.purchaseReturns.indexOf(purchaseReturn),
          1
        );
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
