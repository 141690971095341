import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Globals } from "../globals";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class TransactionServiceService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private extractData(res: Response) {
    return res;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  saveTransaction(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .post(this.globals.apiUrl + "/transactions", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getTransaction() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/transactions", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getFilterTransaction(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/transactions", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateTransaction(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/transactions/" + id, data, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deletetransaction(transaction) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(
        this.globals.apiUrl + "/transactions/deletetransaction/" + transaction,
        { headers: headers }
      )
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  SearchReportOne(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/report_one", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  SearchReportTwo(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/report_two", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  SearchReportThree(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/report_three", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  SearchCashBook(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/cash_book", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  SearchUniversalReport(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/universal_report", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  SearchCashAccount(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/cash_account", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  SearchReceiptPaymentReport(searchParams) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    for (let key of Object.keys(searchParams)) {
      let value = searchParams[key];
      params = params.append(key, value);
    }

    return this.http
      .get(this.globals.apiUrl + "/receipt_payment", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  viewBalance(subgroup_id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    params = params.append("subgroup_id", subgroup_id);

    return this.http
      .get(this.globals.apiUrl + "/balances", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}
