import { ViewChild, ElementRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { PurchaseService } from "../services/purchase.service";
import { ActivatedRoute } from "@angular/router";

import swal from "sweetalert";

@Component({
  selector: "app-purchase-detail",
  templateUrl: "./purchase-detail.component.html",
  styleUrls: ["./purchase-detail.component.scss"]
})
export class PurchaseDetailComponent implements OnInit {
  @ViewChild("closeAddExpenseModal", { static: true })
  closeAddExpenseModal: ElementRef;

  purchase_details: any[];
  purchases: any[];
  errors = [];
  id: number;
  p: any;

  purchase_returnForm = this.fb.group({
    purchase_detail_id: ["", Validators.required],
    return_date: ["", Validators.required],
    item: ["", Validators.required],
    quantity: ["", Validators.required],
    rate: ["", Validators.required],
    amount: ["", Validators.required],
    remarks: [""]
  });

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private purchaseService: PurchaseService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params["id"]; // (+) converts string 'id' to a number
      console.log(this.id);
      this.getPurchase_details(this.id);
    });
  }

  getPurchase_details(id): void {
    this.purchaseService
      .getPurchase_details(id)
      .then(result => {
        this.purchase_details = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  deletePurchase_detail(purchase_detail) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.purchaseService
      .deletePurchase_detail(purchase_detail.id)
      .then(result => {
        swal(result.message, "", "success");
        this.purchase_details.splice(
          this.purchase_details.indexOf(purchase_detail),
          1
        );
      })
      .catch(error => {
        swal(error.error.message, "", "error");
      });
  }

  onSubmit(): void {
    let purchasereturn = this.purchase_returnForm.value;
    this.purchaseService
      .purchaseReturn(purchasereturn)
      .then(result => {
        this.closeAddExpenseModal.nativeElement.click();
        swal("Data Successfully Saved", " ", "success");
        // this.issuereturns.push(result);
        this.purchase_returnForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);
          console.log(this.errors);
        }
      });
  }

  purchase_returnModal(purchase_detail, index) {
    this.purchase_returnForm.setValue({
      purchase_detail_id: purchase_detail.id,
      return_date: "",
      item: purchase_detail.item,
      quantity: purchase_detail.quantity,
      rate: purchase_detail.rate,
      amount: purchase_detail.amount,
      remarks: ""
    });
  }
}
