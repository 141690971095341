import { Component, OnInit } from '@angular/core';
import {AuthService} from './services/auth.service';
import {TokenService} from './services/token.service';
import { Spinkit } from 'ng-http-loader';
import {Router} from "@angular/router";
 @Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'new-payment';
   public spinkit = Spinkit;
public loggedIn:boolean
public user:any;
  constructor(private authService: AuthService, private router: Router, private tokenService: TokenService){


  }
 

  ngOnInit(){
  	this.authService.authStatus.subscribe(value => this.loggedIn=value);

console.log(this.loggedIn);
if (this.loggedIn==true) {
	this.router.navigateByUrl('/dashboard');

	this.user=JSON.parse(localStorage.getItem('user'));
 	 
}else{
	this.router.navigateByUrl('/login');

}

  }


  logout(event: MouseEvent){
  	event.preventDefault();
this.tokenService.remove();

  	this.authService.changeAuthStatus(false);
	this.router.navigateByUrl('/login');
  }
}
