import { NgModule } from "@angular/core";
import { CanActivate, Routes, RouterModule } from "@angular/router";
import { GroupComponent } from "./group/group.component";
import { SubgroupComponent } from "./subgroup/subgroup.component";
import { TransactionTypeComponent } from "./transaction-type/transaction-type.component";
import { TransactionComponent } from "./transaction/transaction.component";
import { TransactionViewComponent } from "./transaction-view/transaction-view.component";
import { BalanceComponent } from "./balance/balance.component";
import { ProjectComponent } from "./project/project.component";
import { PurchaseComponent } from "./purchase/purchase.component";
import { PurchaseViewComponent } from "./purchase-view/purchase-view.component";
import { PurchaseDetailComponent } from "./purchase-detail/purchase-detail.component";
import { ReportOneComponent } from "./report-one/report-one.component";
import { ReportTwoComponent } from "./report-two/report-two.component";
import { ReportThreeComponent } from "./report-three/report-three.component";
import { CashBookComponent } from "./cash-book/cash-book.component";
import { UniversalReportComponent } from "./universal-report/universal-report.component";
import { FundTransferComponent } from "./fund-transfer/fund-transfer.component";
import { GrantRecordComponent } from "./grant-record/grant-record.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";

import { AddmoreComponent } from "./addmore/addmore.component";

import { AfterLoginService } from "./services/after-login.service";
import { BeforeLoginService } from "./services/before-login.service";
import { PurchaseReportComponent } from "./purchase-report/purchase-report.component";
import { StockComponent } from "./stock/stock.component";
import { DepartmentComponent } from "./department/department.component";
import { IssueComponent } from "./issue/issue.component";
import { PurchaseReturnComponent } from "./purchase-return/purchase-return.component";
import { IssueReturnComponent } from "./issue-return/issue-return.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SetqrCodeComponent } from "./setqr-code/setqr-code.component";
import { IssueStockComponent } from "./issue-stock/issue-stock.component";
import { CashAccountComponent } from "./cash-account/cash-account.component";

const routes: Routes = [
  {
    path: "addmore",
    component: AddmoreComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "groups",
    component: GroupComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "sub-groups",
    component: SubgroupComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "transaction-Type",
    component: TransactionTypeComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "transactions",
    component: TransactionComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "transection-view",
    component: TransactionViewComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "balances",
    component: BalanceComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "projects",
    component: ProjectComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase-create",
    component: PurchaseComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchases",
    component: PurchaseViewComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase-details/:id",
    component: PurchaseDetailComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "report-one",
    component: ReportOneComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "report-two",
    component: ReportTwoComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "report-three",
    component: ReportThreeComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "cash-book",
    component: CashBookComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "universal-report",
    component: UniversalReportComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "fund-transfer",
    component: FundTransferComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "grant-record",
    component: GrantRecordComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [BeforeLoginService]
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase-report",
    component: PurchaseReportComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase-stocks",
    component: StockComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase-stock/stock_detail/:item",
    component: StockComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "departments",
    component: DepartmentComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "issues",
    component: IssueComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "issue_returns/model/:id",
    component: IssueComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "IssueStock",
    component: IssueStockComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "purchase_returns",
    component: PurchaseReturnComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "issue_returns",
    component: IssueReturnComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "change-password",
    component: ResetPasswordComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "qrCode/:id",
    component: SetqrCodeComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "cash-account",
    component: CashAccountComponent,
    canActivate: [AfterLoginService]
  },
  {
    path: "stocks",
    component: StockComponent,
    canActivate: [AfterLoginService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
