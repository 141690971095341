import { ViewChild, ElementRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { IssueService } from "../services/issue.service";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-issue-return",
  templateUrl: "./issue-return.component.html",
  styleUrls: ["./issue-return.component.scss"]
})
export class IssueReturnComponent implements OnInit {
  @ViewChild("closeAddExpenseModal", { static: true })
  closeAddExpenseModal: ElementRef;

  issueReturnEditForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    issue_id: ["", Validators.required],
    item: ["", Validators.required],
    quantity: ["", Validators.required],
    return_date: ["", Validators.required],
    remarks: [""]
  });

  issueReturnFilterForm = this.fb.group({
    item: "",
    from_date: "",
    to_date: ""
  });

  issueReturns: any[];
  errors = [];
  editIndex: any;
  searchParams: any;
  p: any;
  items: any[];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private issueService: IssueService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.getissueReturns();
    this.getItems();
  }

  getItems(): void {
    this.masterService.getItems().then(result => {
      this.items = result;
    });
  }

  getissueReturns(): void {
    this.searchParams = this.issueReturnFilterForm.value;
    console.log(this.searchParams);
    this.issueService.getissueReturn(this.searchParams).then(result => {
      console.log(result);
      this.issueReturns = result;
    });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.issueReturnEditForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.getissueReturns();
    }
  }

  setEditModal(issueReturn, index) {
    this.editIndex = index;
    this.issueReturnEditForm.setValue({
      id: issueReturn.id,
      issue_id: issueReturn.issue_id,
      return_date: issueReturn.return_date,
      item: issueReturn.item,
      quantity: issueReturn.quantity,
      remarks: issueReturn.remarks
    });
  }

  onUpdate(id): void {
    let data = this.issueReturnEditForm.value;
    console.log(data);
    this.issueService
      .updateIssueReturn(data, id)
      .then(result => {
        // alert("Success...!")
        this.closeAddExpenseModal.nativeElement.click();
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.issueReturns[this.editIndex] = result;
        this.issueReturnEditForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteIssueReturn(issueReturn) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.issueService
      .deleteIssueReturn(issueReturn.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.issueReturns.splice(this.issueReturns.indexOf(issueReturn), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
