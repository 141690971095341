import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {TransactionServiceService} from '../services/transaction-service.service';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';
import  { Globals } from '../globals'

@Component({
  selector: 'app-cash-account',
  templateUrl: './cash-account.component.html',
  styleUrls: ['./cash-account.component.scss']
})
export class CashAccountComponent implements OnInit {

  sessions:any[]=[];

  	 reportForm =this.fb.group({
  	   group_id:[''],
  	   subgroup_id:[''],
  	   transaction_type_ids:[''],
  	   from_date:['', Validators.required],
  	   to_date:['', Validators.required],
  	 });

  	errors=[];
  	groups:any[];
  	selectedGroup:any;
  	subgroups:any[];
  	selectedSubgroup:any;
  	searchParams:any;
  	transactionTypes:any[];
  	transactions:any[];
  	openingBalance:any;
  	currentDate:any;
  p:any;
  selectedHead:any;

     constructor(private globals:Globals, private fb: FormBuilder, private transactionServiceService: TransactionServiceService, private masterService: MasterService) { }

     ngOnInit() {

    	this.getGroups();
     	this.gettransactionTypes();
      this.sessions=this.globals.sessions;
    }

      getGroups():void {
  	   this.masterService.getGroups() .then(result => {
  	  this.groups=result;
  	   });
  	}

  	getSubgroups():void {
  	   this.masterService.getSubgroups(this.reportForm.getRawValue().group_id) .then(result => {
  	  this.subgroups=result;
  	   });
  	}

  	gettransactionTypes():void {
  	   this.masterService.gettransactionType() .then(result => {
  	  this.transactionTypes=result;
  	   });
  	}



  	onSubmit():void {


   	// 	 this.selectedGroup =  this.groups.find(x => x.id ==  this.reportForm.value.group_id);
   	// 	 this.selectedSubgroup =  this.subgroups.find(x => x.id ==  this.reportForm.value.subgroup_id);
  		   	this.currentDate=  Date.now();
  		   	console.log(this.currentDate);


  		this.searchParams=this.reportForm.value;
  		 console.log(this.searchParams);
  		   this.transactionServiceService.SearchCashAccount(this.searchParams) .then(result => {
  		   	console.log(result);
  		   	this.transactions=result.transactions;
  		   	this.openingBalance=result.opening_balance;
  		   });

  		}

      getDebitTotal(){
         const total = this.transactions.filter(({type}) => type === 'Debit').reduce((sum, item)=> {


        return  +sum +  +item.cash;

    }, 0);
       return total;
      }

      getCreditTotal(){
         const total = this.transactions.filter(({type}) => type === 'Credit').reduce((sum, item)=> {

  return  +sum +  +item.cash ;
    }, 0);
       return total;
      }
      getDebitTotalBank(){
         const total = this.transactions.reduce((sum, item) => +sum + +item.bank, 0) ;
      return total;
      }


      ShowValue(event: Event) {
         let selectedOptions = event.target['options'];
         let selectedIndex = selectedOptions.selectedIndex;
         let selectElementText = selectedOptions[selectedIndex].text;
         this.selectedHead=selectElementText;
      }
      setDates(event: Event) {
         let selectedOptions = event.target['options'];
         let selectedIndex = selectedOptions.selectedIndex;
         let selectElementValue = selectedOptions[selectedIndex].value;
        let   session=this.sessions.find(x => x.id ==  selectElementValue);
           this.reportForm.patchValue({from_date:session.from,to_date:session.to});
           console.log(this.reportForm.value)
      }
}
