import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormArray} from '@angular/forms';
import {TransactionServiceService} from '../services/transaction-service.service';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-grant-record',
  templateUrl: './grant-record.component.html',
  styleUrls: ['./grant-record.component.scss']
})
export class GrantRecordComponent implements OnInit {


    	 reportForm =this.fb.group({
    	   group_id:[''],
    	   subgroup_id:[''],
     	   from_date:['', Validators.required],
    	   to_date:['', Validators.required],
    	 });

    	errors=[];
    	groups:any[];
    	selectedGroup:any;
    	subgroups:any[];
    	selectedSubgroup:any;
    	searchParams:any;
    	transactionTypes:any[];
    	transactions:any[];
    	openingBalance:any;
    	currentDate:any;
    p:any;

       constructor(private fb: FormBuilder, private transactionServiceService: TransactionServiceService, private masterService: MasterService) { }

       ngOnInit() {

      	this.getGroups();
       	this.gettransactionTypes();
      }

        getGroups():void {
    	   this.masterService.getGroups() .then(result => {
    	  this.groups=result;
    	   });
    	}

    	getSubgroups():void {
    	   this.masterService.getSubgroups(this.reportForm.getRawValue().group_id) .then(result => {
    	  this.subgroups=result;
    	   });
    	}

    	gettransactionTypes():void {
    	   this.masterService.gettransactionType() .then(result => {
    	  this.transactionTypes=result;
    	   });
    	}
    	onSubmit():void {


     		 this.selectedGroup =  this.groups.find(x => x.id ==  this.reportForm.value.group_id);
     		 this.selectedSubgroup =  this.subgroups.find(x => x.id ==  this.reportForm.value.subgroup_id);
    		   	this.currentDate=  Date.now();
    		   	console.log(this.currentDate);


    		this.searchParams=this.reportForm.value;
    		 console.log(this.searchParams);
    		   this.transactionServiceService.SearchUniversalReport(this.searchParams) .then(result => {
    		   	console.log(result);
    		   	this.transactions=result.transactions;
    		   	this.openingBalance=result.opening_balance;
    		   });

    		}

        getCreditTotal(){
           const total = this.transactions.filter(({type}) => type === 'Credit').reduce((sum, item)=> {

    return  +sum + +item.bank + +item.cash ;
      }, 0);
         return total;
        }
        getDebitTotal(){
           const total = this.transactions.filter(({type}) => type === 'Debit').reduce((sum, item)=> {

    return  +sum + +item.bank + +item.cash ;
      }, 0);
         return total;
        }

}
