import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.css"]
})
export class GroupComponent implements OnInit {
  p: any;
  groupForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    name: ["", Validators.required]
  });

  groups: any[];
  errors = [];
  editIndex: any;
  user: any;
  constructor(private fb: FormBuilder, private masterService: MasterService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.getGroups();
  }

  getGroups(): void {
    this.masterService
      .getGroups()
      .then(result => {
        this.groups = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.groupForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    let data = this.groupForm.value;

    this.masterService
      .saveGroup(data)
      .then(result => {
        // alert("Success...!")
        swal("Data Successfully Saved", " ", "success");

        this.groups.push(result);
        this.groupForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  setEditModal(group, index) {
    this.editIndex = index;
    this.groupForm.setValue({
      id: group.id,
      name: group.name
    });
  }

  onUpdate(id): void {
    let data = this.groupForm.value;

    console.log(data);
    this.masterService
      .updateGroup(data, id)
      .then(result => {
        // alert("Success...!")
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.groups[this.editIndex] = result;
        this.groupForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteGroup(group) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.masterService
      .deleteGroup(group.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.groups.splice(this.groups.indexOf(group), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
