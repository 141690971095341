import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import  { Globals } from '../globals'
import {Observable} from 'rxjs';    


@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private http: HttpClient, private globals: Globals) { }

   private extractData(res: Response) {
      
        return res;
    }

     private handleError(error: any): Promise<any> {
         return Promise.reject(error);
    }

 // ==================================
 // Purchase
    savePurchase(data){
 	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/json');
   return  this.http.post(this.globals.apiUrl+'/purchases', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError); 
  }

  // ==================================
  // Purchase View
  getPurchases(){
 	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/json');
   return  this.http.get(this.globals.apiUrl+'/purchases', {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }
  getFilterPurchase(searchParams){

    let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }

       return  this.http.get(this.globals.apiUrl+'/purchases', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);    
  }
   deletePurchase(purchase){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

   return  this.http.get(this.globals.apiUrl+'/purchases/deletepurchase/'+purchase, {headers:headers})
    .toPromise()
     .then(this.extractData)
    .catch(this.handleError);
  }

// ==================================
// Purchase Details
   getPurchase_details(id){
   let headers = new HttpHeaders();
   headers.append('Content-Type', 'application/json');
   return  this.http.get(this.globals.apiUrl+'/purchase_details?purchase_id='+id, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

  deletePurchase_detail(purchase_detail){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

   return  this.http.get(this.globals.apiUrl+'/purchase_details/deletepurchase_detail/'+purchase_detail, {headers:headers})
    .toPromise()
     .then(this.extractData)
    .catch(this.handleError);
  }


  purchaseReport(searchParams){
    let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }

       return  this.http.get(this.globals.apiUrl+'/purchase-report', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }

   getPurchase_stocks(){
   let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   return  this.http.get(this.globals.apiUrl+'/purchase-stocks', {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

  detailsModal(stock){
     let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(stock)){
        let value = stock[key];
        params = params.append(key, value);
      }

       return  this.http.get(this.globals.apiUrl+'/purchase-stock/stock_detail', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }


// ===================================
// Purchase Return
// ===================================
purchaseReturn(data){
     let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   return  this.http.post(this.globals.apiUrl+'/purchase_returns', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

  getpurchaseReturn(searchParams){
    let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }
       return  this.http.get(this.globals.apiUrl+'/purchase_returns', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }

 updatePurchaseReturn(data, id){
      let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return  this.http.post(this.globals.apiUrl+'/purchase_returns/'+id, data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);  
  }
  
  deletePurchaseReturn(data){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

   return  this.http.get(this.globals.apiUrl+'/purchase_returns/purchase_returndelete/'+data, {headers:headers})
    .toPromise()
     .then(this.extractData)
    .catch(this.handleError);
  }
}
