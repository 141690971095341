import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators   } from '@angular/forms';
import {MasterService} from '../services/master.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  departmentForm =this.fb.group({
   id: [{value:null, disabled:true}],
   name:['', Validators.required],
 });
  

departments:any[];
 errors=[];
 editIndex:any;
p:any;
 
  constructor(private fb: FormBuilder, private masterService: MasterService) { }

  ngOnInit() {
  	this.getDepartments();
  }

  getDepartments():void {
 	this.masterService.getDepartments() .then(result => {
  this.departments=result;
 	})
          .catch(error => {
           console.log(error);
          });
}

onSubmit():void {
  // TODO: Use EventEmitter with form value
 let data=this.departmentForm.getRawValue();

 if (data.id) {
   this.onUpdate(data.id);
  }else{
    this.onSave()
  }
 
}

onSave(){
  let data=this.departmentForm.value;
 
   this.masterService.saveDepartment(data) .then(result => {
   // alert("Success...!")
   swal("Data Successfully Saved"," ", "success");

this.departments.push(result);
this.departmentForm.reset()
   })
          .catch(error => {
             if (error.status==422) {
              this.errors.push(error.error.name[0]);

              console.log(this.errors); 
            }

          });
}

setEditModal(department, index){
this.editIndex=index;
 this.departmentForm.setValue({
    id: department.id,
    name: department.name,
  })
}

onUpdate(id):void{
  let data=this.departmentForm.value;

console.log(data)
   this.masterService.updateDepartment(data, id) .then(result => {
    // alert("Success...!")
    swal("Data Successfully Updated"," ", "success");
console.log(result);
this.departments[this.editIndex]=result;
this.departmentForm.reset()

    })
          .catch(error => {
             if (error.status==422) {
              this.errors.push(error.error.name[0]);

              console.log(this.errors); 
            }

          });
}

deleteDepartment(department){  

      var r = confirm("Are You Sure!");
      if (r == false) {
       return;
      }     
          this.masterService.deleteDepartment(department.id) .then(result => {
   // alert(result.message)
    swal(result.message,"", "success");
              this.departments.splice(this.departments.indexOf(department), 1);
            }) .catch(error => {
    // alert(error.error.message)
    swal(error.error.message,"","error");
            });
       }

}
