import { Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators   } from '@angular/forms';
import { AuthService } from '../services/auth.service'
import swal from 'sweetalert';

import {TokenService} from '../services/token.service';

import {Router} from "@angular/router";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public loggedIn:boolean
  public user:any;

p:any;
 reset_passwordForm =this.fb.group({
   password:['', Validators.required],
   new_password:['', Validators.required],
   password_confirmation:['', Validators.required],
   email:['', Validators.required],
 });


 users:any[];
 errors=[];

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private tokenService: TokenService) { }

  ngOnInit() {
    this.authService.authStatus.subscribe(value => this.loggedIn=value);

console.log(this.loggedIn);
if (this.loggedIn==true) {

  this.user=JSON.parse(localStorage.getItem('user'));
  this.reset_passwordForm.patchValue({
    'email':this.user.email
  });

}else{
  this.router.navigateByUrl('/login');

}
  }

  onSubmit(){
  let data=this.reset_passwordForm.value;

   this.authService.resetPassword(data) .then(result => {
   // alert("Success...!")
    swal(result.message);
	// this.users.push(result);
	// window.location.reload();
	this.reset_passwordForm.reset();
  this.ngOnInit();
   })
          .catch(error => {
             if (error.status==422) {
              this.errors.push(error.error.name[0]);
    			swal(error.error.message);
              console.log(this.errors);
            }

          });
	}

}
