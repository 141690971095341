import { ViewChild, ElementRef,Component, OnInit } from '@angular/core';
import {  FormBuilder, Validators   } from '@angular/forms';
import {MasterService} from '../services/master.service';
import {IssueService} from '../services/issue.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-issue-stock',
  templateUrl: './issue-stock.component.html',
  styleUrls: ['./issue-stock.component.scss']
})
export class IssueStockComponent implements OnInit {

issueStockFilterForm =this.fb.group({
     item:'',
     department_id:'',
     from_date:'',
     to_date:'',
   });

 departments:any[];  
   stocks:any[];  
   errors=[];
   searchParams:any;
   p:any;
   items:any[];

  constructor(private fb: FormBuilder, private masterService: MasterService, private issueService: IssueService) { }

  ngOnInit() {
  	this.getDepartments();
    this.getItems();
    this.getIssueStocks();

  }

   getDepartments():void {
	   this.masterService.getDepartments() .then(result => {
	  this.departments=result;
	   });
	}

	getItems():void {
     this.masterService.getItems() .then(result => {
    this.items=result;
     });
  }
  getIssueStocks():void {
  	this.searchParams=this.issueStockFilterForm.value;
     console.log(this.searchParams);
       this.issueService.getIssueStock(this.searchParams) .then(result => {
         console.log(result);
         this.stocks=result;
        });
  }


}
