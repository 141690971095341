import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import  { Globals } from '../globals'
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

 constructor( private http: HttpClient, private globals: Globals) { }


    private extractData(res: Response) {
      
        return res;
    }

     private handleError(error: any): Promise<any> {
         return Promise.reject(error);
    }

    // ================================
    // Issue
    // ================================

    saveIssue(data){
  	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/json');

   return  this.http.post(this.globals.apiUrl+'/issues', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

   getIssue(searchParams){
   let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   let params = new HttpParams();
     
     // if (department_id) {
     //    params = params.append('department_id', department_id);
     //   // code...
     // }
     if(searchParams){
       for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }
     }
      
   return  this.http.get(this.globals.apiUrl+'/issues', {headers:headers, params: params})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

  updateIssue(data, id){
       let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   return  this.http.post(this.globals.apiUrl+'/issues/'+id, data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError); 
  }

  deleteIssue(issue){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

   return  this.http.get(this.globals.apiUrl+'/issuess/issuedelete/'+issue, {headers:headers})
    .toPromise()
     .then(this.extractData)
    .catch(this.handleError);
  }

  // ==============================
  // Issue Return
  // ==============================

  issue_returnModal(issue){
    let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(issue)){
        let value = issue[key];
        params = params.append(key, value);
      }

       return  this.http.get(this.globals.apiUrl+'/issue_returns/model', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }
   saveIssueReturn(data){
   let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   return  this.http.post(this.globals.apiUrl+'/issue_returns', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError); 
  }

   getissueReturn(searchParams){
    let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let params = new HttpParams();

      for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }
       return  this.http.get(this.globals.apiUrl+'/issue_returns', {headers:headers, params: params})
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }

   updateIssueReturn(data, id){
      let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return  this.http.post(this.globals.apiUrl+'/issue_returns/'+id, data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);  
  }
  
  deleteIssueReturn(data){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

   return  this.http.get(this.globals.apiUrl+'/issue_returns/issue_returndelete/'+data, {headers:headers})
    .toPromise()
     .then(this.extractData)
    .catch(this.handleError);
  }

//===================================
// Set QR Code
// ==================================
  getQrIssues(id){
    let headers = new HttpHeaders();
   headers.append('Content-Type', 'application/json');
   return  this.http.get(this.globals.apiUrl+'/setqrCode/'+id, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }

  //=================================
  // Issue Stock
  // ================================
   getIssueStock(searchParams){
   let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
   let params = new HttpParams();
     if(searchParams){
       for(let key of Object.keys(searchParams)){
        let value = searchParams[key];
        params = params.append(key, value);
      }
     }
      return  this.http.get(this.globals.apiUrl+'/IssueStock', {headers:headers, params: params})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);
  }
}
