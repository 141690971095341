import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"]
})
export class BalanceComponent implements OnInit {
  balanceForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    date: ["", Validators.required],
    group_id: ["", Validators.required],
    subgroup_id: ["", Validators.required],
    cash: ["", Validators.required],
    bank: ["", Validators.required]
  });
  transactionTypes: any[];

  balances: any[];
  subgroups: any[];
  errors = [];
  groups: any[];
  editIndex: any;
  p: any;
  user: any;
  constructor(private fb: FormBuilder, private masterService: MasterService) {}

  ngOnInit() {
    // this.gettransactionTypes();
    this.user = JSON.parse(localStorage.getItem("user"));

    this.getBalances();

    this.getGroups();
    this.getSubgroups();
  }

  getGroups(): void {
    this.masterService.getGroups().then(result => {
      this.groups = result;
    });
  }
  gettransactionTypes(): void {
    this.masterService.gettransactionType().then(result => {
      this.transactionTypes = result;
    });
  }
  getSubgroups(): void {
    this.masterService
      .getSubgroups(this.balanceForm.getRawValue().group_id)
      .then(result => {
        this.subgroups = result;
      });
  }
  getBalances(): void {
    this.masterService
      .getBalances()
      .then(result => {
        this.balances = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.balanceForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    // TODO: Use EventEmitter with form value
    let data = this.balanceForm.value;
    this.masterService
      .saveBalance(data)
      .then(result => {
        swal("Data Successfully Saved", " ", "success");
        this.balances.push(result);

        // window.location.reload();
        this.balanceForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          swal(error.error.message, "", "error");
        }
      });
  }

  setEditModal(balance, index) {
    this.editIndex = index;
    this.balanceForm.setValue({
      id: balance.id,
      amount: balance.amount,
      group_id: balance.group_id,
      transaction_type_id: balance.transaction_type_id,
      subgroup_id: balance.subgroup_id
    });
  }

  onUpdate(id): void {
    let data = this.balanceForm.value;

    console.log(data);
    this.masterService
      .updateBalance(data, id)
      .then(result => {
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.balances[this.editIndex] = result;
        this.balanceForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deleteBalance(balance) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.masterService
      .deleteBalance(balance.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.balances.splice(this.balances.indexOf(balance), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }

  getTotalbalance() {
    const total = this.balances.reduce(
      (sum: number, item) => +sum + +item.amount,
      0
    );
    return total;
  }
}
