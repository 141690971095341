import { ViewChild, ElementRef, Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormArray
} from "@angular/forms";
import { TransactionServiceService } from "../services/transaction-service.service";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-transaction-view",
  templateUrl: "./transaction-view.component.html",
  styleUrls: ["./transaction-view.component.scss"]
})
export class TransactionViewComponent implements OnInit {
  @ViewChild("closeAddExpenseModal", { static: true })
  closeAddExpenseModal: ElementRef;

  transactionEditForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    date: ["", Validators.required],
    particulars: ["", Validators.required],
    group_id: ["", Validators.required],
    subgroup_id: ["", Validators.required],
    transaction_type_id: ["", Validators.required],
    credit: [""],
    debit: [""],
    payment_mode: [""],
    ref_no: [""],
    remarks: [""]
  });

  transactionFilterForm = this.fb.group({
    from_date: "",
    to_date: ""
  });

  transactions: any[];
  errors = [];
  transactionTypes: any[];
  groups: any[];
  subgroups: any[];
  editIndex: any;
  searchParams: any;
  p: any;
  user: any;

  constructor(
    private fb: FormBuilder,
    private transactionServiceService: TransactionServiceService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.gettransactions();
    this.getGroups();
    this.getSubgroups();
    this.gettransactionTypes();
  }
  getGroups(): void {
    this.masterService.getGroups().then(result => {
      this.groups = result;
    });
  }

  getSubgroups(): void {
    this.masterService
      .getSubgroups(this.transactionEditForm.getRawValue().group_id)
      .then(result => {
        this.subgroups = result;
      });
  }

  gettransactionTypes(): void {
    this.masterService.gettransactionType().then(result => {
      this.transactionTypes = result;
    });
  }

  gettransactions(): void {
    this.searchParams = this.transactionFilterForm.value;
    console.log(this.searchParams);
    this.transactionServiceService
      .getFilterTransaction(this.searchParams)
      .then(result => {
        console.log(result);
        this.transactions = result;
      });

    //  this.transactionServiceService.getTransaction() .then(result => {
    // this.transactions=result;
    //  })
    //         .catch(error => {
    //          console.log(error);
    //         });
  }

  deletetransaction(transaction) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.transactionServiceService
      .deletetransaction(transaction.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");
        this.transactions.splice(this.transactions.indexOf(transaction), 1);
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.transactionEditForm.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.gettransactions();
    }
  }

  setEditModal(transactions, index) {
    this.editIndex = index;
    this.transactionEditForm.setValue({
      id: transactions.id,
      particulars: transactions.particulars,
      group_id: transactions.group_id,
      subgroup_id: transactions.subgroup_id,
      transaction_type_id: transactions.transaction_type_id,
      credit: transactions.credit,
      debit: transactions.debit,
      payment_mode: transactions.payment_mode,
      ref_no: transactions.ref_no,
      date: transactions.date,
      remarks: transactions.remarks
    });
  }

  onUpdate(id): void {
    let data = this.transactionEditForm.value;

    console.log(data);
    this.transactionServiceService
      .updateTransaction(data, id)
      .then(result => {
        // alert("Success...!")
        this.closeAddExpenseModal.nativeElement.click();

        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.transactions[this.editIndex] = result;
        this.transactionEditForm.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  getTotalcredittransaction() {
    if (this.transactions) {
      const total = this.transactions.reduce(
        (sum, item) => +sum + +item.credit,
        0
      );
      return total;
    } else {
      return 0;
    }
  }
  getTotaldebittransaction() {
    if (this.transactions) {
      const total = this.transactions.reduce(
        (sum, item) => +sum + +item.debit,
        0
      );
      return total;
    } else {
      return 0;
    }
  }
}
