import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TokenService} from '../services/token.service';
 
import  { Globals } from '../globals'

import {Observable} from 'rxjs';    
import * as Rx from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

private loggedIn = new Rx.BehaviorSubject<boolean>(this.tokenService.loggedIn());
authStatus = this.loggedIn.asObservable();


  constructor( private http: HttpClient, private globals: Globals, private tokenService: TokenService) { 


  }

changeAuthStatus(value:boolean){
	this.loggedIn.next(value);
}
   private extractData(res: Response) {
      
        return res;
    }

     private handleError(error: any): Promise<any> {
         return Promise.reject(error);
    }




      login(data){
 	let headers = new HttpHeaders();
	headers.append('Content-Type', 'application/json');
   return  this.http.post(this.globals.apiUrl+'/login', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError); 
  }

   resetPassword(data){
      let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return  this.http.post(this.globals.apiUrl+'/change-password', data, {headers:headers})
    .toPromise()
    .then(this.extractData)
    .catch(this.handleError);  
  }
}
