import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Globals } from "../globals";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MasterService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private extractData(res: Response) {
    return res;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  // =======================================
  // For Group
  // =======================================
  saveGroup(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/groups", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getGroups() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/groups", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateGroup(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/groups/" + id, data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteGroup(group) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.globals.apiUrl + "/groups/groupdelete/" + group, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  // ==================================
  // For Sub Group
  // ==================================
  saveSubgroup(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .post(this.globals.apiUrl + "/subgroups", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getSubgroups(group_id?) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();

    if (group_id) {
      params = params.append("group_id", group_id);
      // code...
    }

    return this.http
      .get(this.globals.apiUrl + "/subgroups", {
        headers: headers,
        params: params
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateSubgroup(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/subgroups/" + id, data, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteSubgroup(subgroup) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.globals.apiUrl + "/subgroups/subgroupdelete/" + subgroup, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  // ====================================
  // For Transaction Type
  // ====================================
  savetransactionType(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/transaction_types", data, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  gettransactionType() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/transaction_types", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updatetransactionTypes(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/transaction_types/" + id, data, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deletetransactionType(transactionType) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(
        this.globals.apiUrl +
          "/transaction_types/transaction_typesdelete/" +
          transactionType,
        { headers: headers }
      )
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // ===================================
  // Balances
  // ===================================
  saveBalance(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .post(this.globals.apiUrl + "/balances", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getBalances() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/balances", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateBalance(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/balances/" + id, data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteBalance(balance) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.globals.apiUrl + "/balances/deletebalance/" + balance, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  // ============================
  //Projetc

  saveProject(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .post(this.globals.apiUrl + "/projects", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getProjects() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/projects", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateProject(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/projects/" + id, data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteProject(project) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.globals.apiUrl + "/projects/deleteproject/" + project, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  //===============================

  getItems() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/items", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getParties() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/parties", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // =======================================
  // For Department
  // =======================================
  saveDepartment(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/departments", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getDepartments() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/departments", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateDepartment(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/departments/" + id, data, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteDepartment(department) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(
        this.globals.apiUrl + "/departments/departmentdelete/" + department,
        { headers: headers }
      )
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  saveStock(data) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/stocks", data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getStocks() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .get(this.globals.apiUrl + "/stocks", { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  updateStock(data, id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(this.globals.apiUrl + "/stocks/" + id, data, { headers: headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteStock(stock) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.globals.apiUrl + "/stocks/stockdelete/" + stock, {
        headers: headers
      })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}
