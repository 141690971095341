import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MasterService } from "../services/master.service";
import swal from "sweetalert";

@Component({
  selector: "app-transaction-type",
  templateUrl: "./transaction-type.component.html",
  styleUrls: ["./transaction-type.component.css"]
})
export class TransactionTypeComponent implements OnInit {
  transactionTypeFrom = this.fb.group({
    id: [{ value: null, disabled: true }],
    name: ["", Validators.required]
  });

  transactionTypes: any[];
  errors = [];
  editIndex: any;
  p: any;
  user: any;

  constructor(private fb: FormBuilder, private masterService: MasterService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.gettransactionTypes();
  }

  gettransactionTypes(): void {
    this.masterService
      .gettransactionType()
      .then(result => {
        this.transactionTypes = result;
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmit(): void {
    // TODO: Use EventEmitter with form value
    let data = this.transactionTypeFrom.getRawValue();

    if (data.id) {
      this.onUpdate(data.id);
    } else {
      this.onSave();
    }
  }

  onSave() {
    // TODO: Use EventEmitter with form value
    let data = this.transactionTypeFrom.value;
    this.masterService
      .savetransactionType(data)
      .then(result => {
        // alert('Success');
        swal("Data Successfully Saved", " ", "success");
        this.transactionTypes.push(result);
        this.transactionTypeFrom.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);
          console.log(this.errors);
        }
      });
  }

  setEditModal(transactionTypes, index) {
    this.editIndex = index;
    this.transactionTypeFrom.setValue({
      id: transactionTypes.id,
      name: transactionTypes.name
    });
  }

  onUpdate(id): void {
    let data = this.transactionTypeFrom.value;

    console.log(data);
    this.masterService
      .updatetransactionTypes(data, id)
      .then(result => {
        // alert("Success...!")
        swal("Data Successfully Updated", " ", "success");
        console.log(result);
        this.transactionTypes[this.editIndex] = result;
        this.transactionTypeFrom.reset();
      })
      .catch(error => {
        if (error.status == 422) {
          this.errors.push(error.error.name[0]);

          console.log(this.errors);
        }
      });
  }

  deletetransactionType(transactionType) {
    var r = confirm("Are You Sure!");
    if (r == false) {
      return;
    }
    this.masterService
      .deletetransactionType(transactionType.id)
      .then(result => {
        // alert(result.message)
        swal(result.message, "", "success");

        this.transactionTypes.splice(
          this.transactionTypes.indexOf(transactionType),
          1
        );
      })
      .catch(error => {
        // alert(error.error.message)
        swal(error.error.message, "", "error");
      });
  }
}
